'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { SearchFilterContentTypes, SearchOption } from 'stockblocks-client';

import { getSearchUrl } from '../utils/SearchUtils';
import { SearchOrigins } from '../utils/constants';
import ProminentSearch from './ProminentSearch';
import { getUserDataAction } from './getUserDataAction';

interface ProminentSearchContainerProps {
    imagesUrl: string;
    contentTypes: SearchOption[];
    contentType: SearchFilterContentTypes;
    setContentType: (newContentType: SearchFilterContentTypes) => void;
    showDropdown?: boolean;
    alignment?: 'left' | 'center';
    showContentToggles?: boolean;
}

const ProminentSearchContainer = ({
    imagesUrl,
    contentTypes,
    contentType,
    setContentType,
    showDropdown = true,
    alignment = 'left',
    showContentToggles,
}: ProminentSearchContainerProps) => {
    const pathname = usePathname();

    const [auth, setAuth] = useState<{
        hasAnySubscription?: boolean;
        isEnterprise?: boolean;
        permissions?: {
            pproPluginEnabled?: boolean;
        };
    }>({});
    const [showSuggestions, setShowSuggestions] = useState(false);
    const handleSubmit = (contentType: string, searchTerm: string, searchOrigin: string) => {
        const searchUrl = getSearchUrl({
            contentType: contentType as SearchFilterContentTypes,
            searchTerm,
            searchOrigin: (searchOrigin as SearchOrigins) || SearchOrigins.SEARCH_BAR,
        });
        window.location.href = searchUrl;
    };
    useEffect(() => {
        getUserDataAction().then((data) => {
            if (data) {
                setAuth(data.auth);
            }
        });
    }, []);

    const { hasAnySubscription = false, isEnterprise = false, permissions: { pproPluginEnabled = false } = {} } = auth;

    return (
        <ProminentSearch
            contentType={contentType}
            onDropdownChange={(contentType) => {
                setContentType(contentType as SearchFilterContentTypes);
            }}
            onSubmit={handleSubmit}
            searchTerm={''}
            userHasAnySubscription={hasAnySubscription}
            isEnterpriseMember={isEnterprise}
            pproPluginEnabled={pproPluginEnabled}
            isSearchAppLoaded={() => false}
            imagesUrl={imagesUrl}
            prominentSearchOptions={contentTypes}
            contentTypeFromPrevious={contentType || SearchFilterContentTypes.All_videos_content_type}
            showDropDown={showDropdown}
            showContentToggles={showContentToggles || ['/', '/audio'].includes(pathname)}
            alignment={alignment}
            setShowSuggestions={setShowSuggestions}
            showSuggestions={showSuggestions}
        />
    );
};

export default ProminentSearchContainer;
